import { makeAutoObservable } from 'mobx';
import { ethers } from 'ethers';
import checkContractAbi from './checkContractAbi.json';
import { CheckContractMethods, DECIMAL_DEFAULT_POINT } from './';
import { metamaskService } from '@/shared/services/metamask';

class CheckContract {
  balanceOfValue = '';

  constructor() {
    makeAutoObservable(this);
  }

  requestContract = async (method: string, ...args: unknown[]) => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        process.env.REACT_APP_CHECK_CONTRACT_ADDRESS,
        checkContractAbi,
        signer
      );
      const data = await contract[method](...args);

      return data;
    }
  };

  balanceOf = async () => {
    try {
      const balanceOf = await this.requestContract(
        CheckContractMethods.BalanceOf,
        metamaskService.wallet.account
      );
      this.balanceOfValue = ethers.utils.formatUnits(balanceOf, DECIMAL_DEFAULT_POINT);
    } catch (err) {
      this.balanceOfValue = ethers.utils.formatUnits(0, DECIMAL_DEFAULT_POINT);
    }
  };

  allowance = async (address: string) => {
    const allowance = await this.requestContract(
      CheckContractMethods.Allowance,
      metamaskService.wallet.account,
      address
    );

    return allowance;
  };

  increaseAllowance = async (allowanceValue: number | string, address: string) => {
    const increaseAllowanceTransaction = await this.requestContract(
      CheckContractMethods.IncreaseAllowance,
      address,
      allowanceValue
    );
    await increaseAllowanceTransaction.wait();
  };
}

export const checkContract = new CheckContract();
