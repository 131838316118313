import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  mainWrapper: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 20,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 8
    }
  },
  container: {
    height: '100%',
    padding: '30px 37px',
    paddingBottom: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: '#0C0C0C',
    margin: 3,
    borderRadius: '15px',
    [theme.breakpoints.down('md')]: {
      padding: '33px 25px 40px 25px'
    },
    [theme.breakpoints.down('xs')]: {
      margin: 1,
      borderRadius: '20px',
      padding: '18px 6px'
    }
  },
  infoWrapper: {
    display: 'flex'
  },
  iconButton: {
    width: 35,
    height: 35,
    [theme.breakpoints.down('xs')]: {
      width: 24,
      height: 24
    }
  },
  title: {
    marginLeft: 5,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '28px',
    letterSpacing: '0.2px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      lineHeight: '22px'
    }
  },
  secondTitle: {
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '24px',
    letterSpacing: '0.2px',
    color: '#95ADAC',
    marginTop: 4,
    marginLeft: 5,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12,
      lineHeight: '22px'
    }
  },
  secondTitleOffset: {
    marginLeft: 46
  },
  error: {
    color: '#EE505A'
  },
  success: {
    background:
      'linear-gradient(73.17deg, rgba(118, 252, 178, 0.7) 26.6%, rgba(12, 177, 123, 0.7) 93.51%)',
    textFillColor: 'transparent',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
  },
  line: {
    height: 1,
    background: 'linear-gradient(90deg, #00B897 -69.21%, #00F2FE 100%)',
    margin: '35px 0 30px 0',
    [theme.breakpoints.down('xs')]: {
      margin: '14px 0 16px 0'
    }
  },
  drawer: {
    '& .MuiDrawer-paper': {
      width: '100%',
      position: 'absolute',
      transition: 'auto',
      borderRadius: 17,
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        borderRadius: 20
      }
    }
  },
  paper: {
    overflow: 'hidden',
    overflowY: 'hidden',
    backgroundColor: 'transparent'
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  img: {
    marginRight: 6,
    [theme.breakpoints.down('xs')]: {
      height: 24,
      width: 24
    }
  }
}));
