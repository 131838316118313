import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { SwapDrawer } from '@/shared/components/swapDrawer';
import { useStyles, IFailedDrawer, IFailedDrawerBody } from '.';
import { observer } from 'mobx-react';

export const FailedDrawer = observer(({ open, handleClickBackBtn, title, body }: IFailedDrawer) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <SwapDrawer
      secondTitle={title === 'unstaking-denied-by-user' ? t('you-are-keep-staking') : ''}
      title={t(title)}
      open={open}
      handleClickBackBtn={handleClickBackBtn}
      error
    >
      <Fragment>
        {body?.map(({ name, value }: IFailedDrawerBody) => (
          <div className={classes.wrapper}>
            <p className={classes.text}>{name}</p>
            <p className={classes.text}>{value}</p>
          </div>
        ))}
      </Fragment>
    </SwapDrawer>
  );
});
