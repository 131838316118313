import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { StakeButton } from '@/shared/components/stakeButton';
import { metamaskService } from '@/shared/services/metamask';
import { useStyles, ISwitchNetworkButton } from './';

export const SwitchNetworkButton = observer(({ className }: ISwitchNetworkButton) => {
  const { t } = useTranslation('common');
  const classes = useStyles();

  const handleClick = () => {
    metamaskService.switchToActualNetwork();
  };

  return (
    <StakeButton
      text={t('switch-network')}
      onClick={handleClick}
      className={clsx([className, classes.button])}
    />
  );
});
