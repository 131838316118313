import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles, ISuccessfulDrawer, ISuccessfulDrawerBody } from '.';
import { SwapDrawer } from '@/shared/components/swapDrawer';

export const SuccessfulDrawer = ({ open, handleClickBackBtn, title, body }: ISuccessfulDrawer) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <SwapDrawer title={t(title)} open={open} handleClickBackBtn={handleClickBackBtn} success>
      <Fragment>
        {body?.map(({ name, value }: ISuccessfulDrawerBody) => (
          <div className={classes.wrapper}>
            <p className={classes.text}>{name}</p>
            <p className={classes.text}>{value}</p>
          </div>
        ))}
      </Fragment>
    </SwapDrawer>
  );
};
