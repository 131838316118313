import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: 498,
    position: 'relative',
    borderRadius: 20,
    background: '#121515',
    height: 665,
    [theme.breakpoints.down('md')]: {
      width: 498,
      height: 665
    },
    [theme.breakpoints.down('xs')]: {
      width: 288,
      height: 619
    },

    '&::before': {
      content: '""',
      position: 'absolute',
      top: -2,
      right: -2,
      bottom: -2,
      left: -2,
      zIndex: -1,
      borderRadius: 20,
      background: 'linear-gradient(90deg, #00B897 -69.21%, #00F2FE 100%)',
      [theme.breakpoints.down('xs')]: {
        top: -1,
        right: -1,
        bottom: -1,
        left: -1
      }
    }
  },
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
    padding: '26px 25px 30px 25px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      padding: '26px 25px 30px 25px'
    },
    [theme.breakpoints.down('xs')]: {
      padding: '24px 9px'
    }
  },
  tabs: {
    width: 470,
    '& > div > div': {
      '& > button': {
        padding: '0 0 29px 0 !important',
        width: 235,
        [theme.breakpoints.down('md')]: {
          width: 219,
          padding: '0 0 29px 0 !important'
        },
        [theme.breakpoints.down('xs')]: {
          width: 135,
          padding: '0 0 19px 0 !important'
        }
      },
      '& > button:not(:first-child):after': {
        content: '" "',
        position: 'absolute',
        borderLeft: '1px solid #EFEFEF',
        left: 0,
        height: 38
      }
    }
  },
  backdrop: {
    position: 'absolute',
    backgroundColor: 'rgba(74, 106, 99, 0.4)',
    zIndex: 3,
    borderRadius: 20
  }
}));
