import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginTop: 24,
    [theme.breakpoints.down('md')]: {
      marginTop: 18
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 16
    }
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  text: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '24px',
    color: '#95ADAC',
    margin: '3px 0 0 0',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  }
}));
