import React, { useState, Fragment } from 'react';
import { BigNumber, ethers } from 'ethers';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';
import { Decimal } from 'decimal.js';
import { StakeInfo } from '@/entities/stakeInfo';
import { StakeButton } from '@/shared/components/stakeButton';
import { EarnedAndClaim } from '@/entities/earnedAndClaim';
import { InputStakeValue } from '@/shared/components/inputStakeValue';
import { metamaskService, CHAIN_DATA } from '@/shared/services/metamask';
import { checkStakerContract } from '@/shared/services/checkStakerContract';
import { SwitchNetworkButton } from '@/entities/switchNetworkButton';
import {
  checkContract,
  ZERO,
  DECIMAL_DEFAULT_FRACTIONAL_PART,
  DECIMAL_DEFAULT_POINT
} from '@/shared/services/checkContract';
import { useStyles, IStake, IStakeFormData, stakeValidationSchema } from './';

export const Stake = observer(
  ({ handleConnect, handleClickClaim, handleSuccess, handleFailed }: IStake) => {
    const classes = useStyles();
    const [loaded, setLoaded] = useState(false);
    const { t } = useTranslation('common');
    const {
      handleSubmit,
      setValue,
      watch,
      control,
      formState: { errors, isValid }
    } = useForm<IStakeFormData>({
      resolver: yupResolver(stakeValidationSchema(t, checkContract.balanceOfValue)),
      mode: 'onChange'
    });

    const stakeValue = Number(watch('value')?.replace(/,/g, '')) || ZERO;

    const stakeBody = [
      {
        name: t('stake'),
        value: `${ethers.utils.commify(
          ethers.utils.formatUnits(
            BigNumber.from(
              new Decimal(isValid ? stakeValue?.toFixed(DECIMAL_DEFAULT_POINT) : ZERO)
                .mul(DECIMAL_DEFAULT_FRACTIONAL_PART)
                .toFixed()
            ),
            DECIMAL_DEFAULT_POINT
          )
        )} ${t('check')}`
      }
    ];

    const clearStakeValue = () => {
      setValue('value', '');
    };

    const successCallBackFn = async () => {
      handleSuccess('stake-completed-successfully', stakeBody);
      await checkStakerContract.totalStaked();
      checkStakerContract.pendingReward();
      checkStakerContract.stakedOf();
      checkStakerContract.depositsOf();
      checkContract.balanceOf();
      clearStakeValue();
      setLoaded(false);
    };

    const errorCallBackFn = () => {
      handleFailed('transaction-denied-by-user', []);
      clearStakeValue();
      setLoaded(false);
    };

    const handleStake = (data: IStakeFormData) => {
      setLoaded(true);
      checkStakerContract.stake(data.value?.replace(/,/g, ''), successCallBackFn, errorCallBackFn);
    };

    return (
      <div className={classes.container}>
        <div>
          <p className={classes.title}>{t('stake-check-for-days')}</p>
          <StakeInfo stakeValue={isValid ? stakeValue : ZERO} />
          {metamaskService.wallet.account && (
            <Fragment>
              <EarnedAndClaim onClickClaim={handleClickClaim} />
              <InputStakeValue
                errorMessage={errors?.value?.message}
                setValue={setValue}
                maxValue={checkContract.balanceOfValue}
                control={control}
              />
            </Fragment>
          )}
        </div>
        {!metamaskService.wallet.account ? (
          <StakeButton
            text={t('connect-wallet')}
            onClick={handleConnect}
            className={classes.button}
          />
        ) : (
          <div>
            {Number(metamaskService.wallet.chainId) !==
            CHAIN_DATA?.[process.env.REACT_APP_CHAIN_ID as keyof typeof CHAIN_DATA].chainId ? (
              <SwitchNetworkButton />
            ) : (
              <StakeButton
                text={t('stake')}
                onClick={handleSubmit(handleStake)}
                className={classes.button}
                loaded={loaded}
              />
            )}
          </div>
        )}
      </div>
    );
  }
);
