import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ethers } from 'ethers';
import { Decimal } from 'decimal.js';
import { useStyles, ILockDrawer } from '.';
import { SwapDrawer } from '@/shared/components/swapDrawer';
import { StakeButton } from '@/shared/components/stakeButton';
import { RewardsLocked } from '@/entities/rewardsLocked';
import { checkLockerContract } from '@/shared/services/checkLockerContract';
import {
  checkContract,
  DECIMAL_DEFAULT_FRACTIONAL_PART,
  DECIMAL_DEFAULT_POINT,
  ZERO
} from '@/shared/services/checkContract';

export const LockDrawer = observer(
  ({ open, handleClickBackBtn, lockValue, handleSuccess, handleFailed }: ILockDrawer) => {
    const [loaded, setLoaded] = useState(false);
    const classes = useStyles();
    const { t } = useTranslation('common');

    const lockBody = [
      {
        name: t('locked'),
        value: `${ethers.utils.commify(
          ethers.utils.formatUnits(
            new Decimal(lockValue || ZERO).mul(DECIMAL_DEFAULT_FRACTIONAL_PART).toFixed(),
            DECIMAL_DEFAULT_POINT
          )
        )} ${t('check')}`
      }
    ];

    const successCallbackFn = () => {
      handleClickBackBtn();
      handleSuccess('lock-is-completed-successfully', lockBody);
      checkContract.balanceOf();
      checkLockerContract.totalLocked();
      checkLockerContract.lockedOf();
      setLoaded(false);
    };

    const errorCallbackFn = () => {
      handleClickBackBtn();
      handleFailed(
        checkLockerContract.lockEnd ? 'Lock period ended' : 'transaction-denied-by-user',
        []
      );
      setLoaded(false);
    };

    const handleLock = () => {
      setLoaded(true);
      checkLockerContract.islockEnd();
      checkLockerContract.lock(lockValue, successCallbackFn, errorCallbackFn);
    };

    return (
      <SwapDrawer
        title={t('are-you-sure-to-lock')}
        open={open}
        handleClickBackBtn={handleClickBackBtn}
      >
        <RewardsLocked value={lockValue} />
        <StakeButton
          text={t('lock')}
          className={classes.lock}
          onClick={handleLock}
          loaded={loaded}
        />
      </SwapDrawer>
    );
  }
);
