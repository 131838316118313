import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    maxHeight: 595,
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  wrapper: {
    marginTop: 20,
    [theme.breakpoints.down('md')]: {
      marginTop: 32
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 24
    }
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '40px',
    color: theme.palette.primary.main,
    margin: '32px 0 0 0'
  },
  info: {
    width: 'fit-content',
    margin: '2px 0 0 0',
    fontFamily: 'Gothic A1',
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: 14,
    lineHeight: '28px',
    letterSpacing: 0.2,
    background: 'linear-gradient(90deg, #00B897 -69.21%, #00F2FE 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent',
    cursor: 'pointer'
  },
  button: {
    marginTop: 40
  },
  line: {
    margin: '16px 0',
    height: 1,
    width: '100%',
    background: 'linear-gradient(90deg, #00B897 -69.21%, #00F2FE 100%)',
    [theme.breakpoints.down('xs')]: {
      marginBottom: 8
    }
  }
}));
