import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ethers } from 'ethers';
import { FilledButton } from '@/shared/components/filledButton';
import { checkStakerContract } from '@/shared/services/checkStakerContract';
import { useStyles, IEarnedAndClaim } from '.';

export const EarnedAndClaim = observer(({ onClickClaim }: IEarnedAndClaim) => {
  const [error, setError] = useState<string | null>(null);
  const classes = useStyles({ isError: !!error });
  const { t } = useTranslation('common');

  const handleClaimClick = () => {
    if (checkStakerContract?.pendingRewardValue?.toNumber() === 0) {
      setError(t('nothing-to-claim'));
      return;
    }

    onClickClaim();
  };

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <div>
          <p className={classes.earned}>{t('you-have-earned')}</p>
          <p className={classes.value}>
            {ethers.utils.commify(
              ethers.utils.formatUnits(checkStakerContract.pendingRewardValue, 6)
            )}
          </p>
        </div>
        <FilledButton
          text={t('claim')}
          className={classes.claim}
          onClick={handleClaimClick}
          isSmall
        />
      </div>
      <p className={classes.error}>{error}</p>
    </div>
  );
});
