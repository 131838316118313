import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'column'
  },
  title: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 18,
    lineHeight: '32px',
    color: theme.palette.primary.main,
    margin: '32px 0 0 0',
    [theme.breakpoints.down('md')]: {
      fontSize: 18,
      lineHeight: '32px'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 24,
      fontSize: 14,
      lineHeight: '28px'
    }
  },
  button: {
    marginTop: 40,
    [theme.breakpoints.down('xs')]: {
      marginTop: 23
    }
  }
}));
