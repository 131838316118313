import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  textField: {
    width: '100%',
    '& > div': {
      height: 66,
      color: theme.palette.primary.main,
      [theme.breakpoints.down('xs')]: {
        height: 45,
        width: 185
      },
      '& > input': {
        padding: '19px 16px 19px 30px',
        [theme.breakpoints.down('xs')]: {
          padding: '9px 8px 8px 16px',
          fontSize: 14
        }
      },
      '& > fieldset': {
        borderRadius: 14
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: `${theme.palette.primary.main} !important`
      }
    },
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none'
      }
    },
    '& .MuiFormHelperText-root': {
      position: 'initial'
    }
  }
}));
