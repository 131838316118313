import React from 'react';
import { useTranslation } from 'react-i18next';
import { ethers } from 'ethers';
import { DECIMAL_DEFAULT_POINT } from '@/shared/services/checkContract';
import { FilledButton } from '@/shared/components/filledButton';
import { IUnstakeItem } from '../unstakeTable';
import { useStyles, IMobileUnstakeView } from './';

export const MobileUnstakeView = ({ config, handleClickUnstake }: IMobileUnstakeView) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  if (config?.length) {
    return (
      <div className={classes.container}>
        {config?.map((el: IUnstakeItem, index: number) => (
          <>
            <div className={classes.mainContainer}>
              <div className={classes.wrapper}>
                <div>
                  <p className={classes.header}>{t('your-stake')}</p>
                  <p className={classes.text}>
                    {ethers.utils.commify(
                      ethers.utils.formatUnits(el?.stakeAmount, DECIMAL_DEFAULT_POINT)
                    )}
                  </p>
                </div>
                <div>
                  <p className={classes.header}>{t('unstake-date')}</p>
                  <p className={classes.text}>{el?.stakeTime}</p>
                </div>
              </div>
              <div className={classes.wrapper}>
                <div>
                  <p className={classes.header}>{t('your-share')}</p>
                  <p className={classes.text}>{`${parseFloat(el?.share).toFixed(3)} %`}</p>
                </div>
                <FilledButton
                  text={t('unstake')}
                  className={classes.unstake}
                  onClick={() => handleClickUnstake(el)}
                  isSmall
                />
              </div>
            </div>
            {index !== config?.length - 1 && <div className={classes.line} />}
          </>
        ))}
      </div>
    );
  } else
    return (
      <div className={classes.noEntriesContainer}>
        <p className={classes.noEntries}>{t('no-stakes-yet')}</p>
      </div>
    );
};
