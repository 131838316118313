import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { ethers } from 'ethers';
import {
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody
} from '@material-ui/core';
import { FilledButton } from '@/shared/components/filledButton';
import { useStyles, IUnstakeItem, IUnstakeTable } from './';
import { DECIMAL_DEFAULT_POINT } from '@/shared/services/checkContract';

export const UnstakeTable = observer(({ config, handleClickUnstake }: IUnstakeTable) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  if (config?.length) {
    return (
      <TableContainer className={classes.container}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>{t('your-stake')}</TableCell>
              <TableCell>{t('your-share')}</TableCell>
              <TableCell>{t('unstake-date')}</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {config?.map((el: IUnstakeItem) => (
              <TableRow>
                <TableCell>
                  {ethers.utils.commify(
                    ethers.utils.formatUnits(el.stakeAmount, DECIMAL_DEFAULT_POINT)
                  )}
                </TableCell>
                <TableCell>{`${parseFloat(el.share).toFixed(3)} %`}</TableCell>
                <TableCell>{el.stakeTime}</TableCell>
                <TableCell className={classes.lastCell}>
                  <FilledButton
                    text={t('unstake')}
                    className={classes.unstake}
                    onClick={() => handleClickUnstake(el)}
                    isSmall
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  } else
    return (
      <div className={classes.noEntriesContainer}>
        <p className={classes.noEntries}>{t('no-stakes-yet')}</p>
      </div>
    );
});
