import React from 'react';
import MuiTooltip from '@material-ui/core/Tooltip';
import Fade from '@material-ui/core/Fade';
import { ITooltip } from './';

export const Tooltip = ({ children, title, placement, classes, ...props }: ITooltip) => {
  return (
    <MuiTooltip
      classes={classes}
      title={title}
      arrow
      placement={placement || 'bottom-start'}
      enterTouchDelay={0}
      TransitionProps={{ timeout: 0 }}
      TransitionComponent={Fade}
      {...props}
    >
      {children}
    </MuiTooltip>
  );
};
