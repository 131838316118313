import { makeAutoObservable } from 'mobx';
import moment from 'moment';
import { BigNumber, ethers } from 'ethers';
import { Decimal } from 'decimal.js';
import checkStakerContractAbi from './checkStakerContractAbi.json';
import { IDepositsOf, CheckStakerContractMethods } from './';
import {
  checkContract,
  DECIMAL_DEFAULT_FRACTIONAL_PART,
  DECIMAL_PERCENT,
  DECIMAL_PERCENT_FRACTIONAL_PART,
  ZERO
} from '../checkContract';
import { IUnstakeItem } from '@/entities/unstakeTable';

class CheckStakerContract {
  totalStakedValue: BigNumber = BigNumber.from(ZERO);

  stakeDeposits?: Array<IUnstakeItem>;

  stakedOfValue: BigNumber = BigNumber.from(ZERO);

  pendingRewardValue: BigNumber = BigNumber.from(ZERO);

  period: BigNumber = BigNumber.from(ZERO);

  constructor() {
    makeAutoObservable(this);
  }

  requestContract = async (method: string, ...args: unknown[]) => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        process.env.REACT_APP_CHECK_STAKER_CONTRACT_ADDRESS,
        checkStakerContractAbi,
        signer
      );
      const data = await contract[method](...args);

      return data;
    }
  };

  claim = async (successCallbackFn?: () => void, errorCallbackFn?: () => void) => {
    try {
      const claimTransaction = await this.requestContract(CheckStakerContractMethods.Claim);
      await claimTransaction.wait();
      if (successCallbackFn) {
        successCallbackFn();
      }
    } catch (err) {
      if (errorCallbackFn) {
        errorCallbackFn();
      }
    }
  };

  totalStaked = async () => {
    try {
      const totalStaked = await this.requestContract(CheckStakerContractMethods.TotalStaked);
      this.totalStakedValue = totalStaked;
    } catch (err) {
      this.totalStakedValue = BigNumber.from(ZERO);
    }
  };

  depositsOf = async () => {
    try {
      const stakeDeposits = await this.requestContract(CheckStakerContractMethods.StakeDeposits);

      this.stakeDeposits = stakeDeposits.map((el: IDepositsOf, index: number) => {
        const stakeTime = moment
          .unix(el.stakeTime.toNumber())
          .add(ethers.utils.formatUnits(this.period, ZERO), 'days')
          .format('MMM Do, YYYY');

        return {
          stakeAmount: el.stakeAmount,
          share: ethers.utils.formatUnits(
            el.stakeAmount
              ?.mul(DECIMAL_PERCENT_FRACTIONAL_PART)
              ?.div(this.totalStakedValue.toString() || 1) || ZERO,
            DECIMAL_PERCENT
          ),
          stakeTime,
          index
        };
      });
    } catch (err) {
      this.stakeDeposits = undefined;
    }
  };

  pendingReward = async () => {
    try {
      const pendingReward = await this.requestContract(CheckStakerContractMethods.PendingReward);
      this.pendingRewardValue = pendingReward;
    } catch (err) {
      this.pendingRewardValue = BigNumber.from(ZERO);
    }
  };

  withdraw = async (
    unstakeValue = '',
    index = 0,
    successCallbackFn = () => {},
    errorCallbackFn = () => {}
  ) => {
    try {
      const unstake = new Decimal(unstakeValue || ZERO)
        .mul(DECIMAL_DEFAULT_FRACTIONAL_PART)
        .toFixed();
      const withdrawTransaction = await this.requestContract(
        CheckStakerContractMethods.Withdraw,
        index,
        unstake
      );
      await withdrawTransaction.wait();
      successCallbackFn();
    } catch (err) {
      errorCallbackFn();
    }
  };

  stakedOf = async () => {
    const stakedOf = await this.requestContract(CheckStakerContractMethods.StakedOf);
    this.stakedOfValue = stakedOf;
  };

  unstakePercentage = async (index = 0) => {
    const percentage = await this.requestContract(
      CheckStakerContractMethods.UnstakePercentage,
      index
    );

    return percentage;
  };

  stakePeriod = async () => {
    const period = await this.requestContract(CheckStakerContractMethods.StakePeriod);
    this.period = period;
  };

  stake = async (stakeValue = '0', successCallbackFn = () => {}, errorCallbackFn = () => {}) => {
    const allowance = await checkContract.allowance(
      process.env.REACT_APP_CHECK_STAKER_CONTRACT_ADDRESS
    );
    const allowanceValue = allowance.toNumber().toFixed();
    const stake = new Decimal(stakeValue).mul(DECIMAL_DEFAULT_FRACTIONAL_PART).toFixed();

    if (Number(allowanceValue) < Number(stake)) {
      try {
        await checkContract.increaseAllowance(
          stake,
          process.env.REACT_APP_CHECK_STAKER_CONTRACT_ADDRESS
        );
        const stakeTransaction = await this.requestContract(
          CheckStakerContractMethods.Deposit,
          stake
        );
        await stakeTransaction.wait();
        successCallbackFn();
      } catch (err) {
        errorCallbackFn();
      }
    } else {
      try {
        const stakeTransaction = await this.requestContract(
          CheckStakerContractMethods.Deposit,
          stake
        );
        await stakeTransaction.wait();
        successCallbackFn();
      } catch (err) {
        errorCallbackFn();
      }
    }
  };
}

export const checkStakerContract = new CheckStakerContract();
