import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginTop: 24,
    [theme.breakpoints.down('xs')]: {
      marginTop: 0
    }
  },
  img: {
    marginLeft: 24,
    width: 40,
    height: 40,
    marginRight: 8,
    [theme.breakpoints.down('xs')]: {
      width: 32,
      height: 32,
      marginRight: 4,
      marginLeft: 10
    }
  },
  checkWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 16,
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      height: 40
    },
    [theme.breakpoints.down('xs')]: {
      width: 77,
      height: 32,
      marginTop: 7
    }
  },
  check: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '28px',
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column'
  },
  balance: {
    fontSize: 14,
    lineHeight: '24px',
    color: '#95ADAC',
    marginTop: 4,
    [theme.breakpoints.down('xs')]: {
      fontSize: 10,
      lineHeight: '24px',
      fontWeight: 400
    }
  }
}));
