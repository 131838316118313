import { makeStyles } from '@material-ui/core/styles';
import maxBtn from '@/shared/images/maxBtn.png';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 26px',
    background: `url(${maxBtn}) no-repeat`,
    backgroundSize: '100% 100%',
    height: 48,
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      width: 58,
      height: 33,
      padding: '3px 16px'
    },
    '&:hover': {
      filter: 'drop-shadow(0px -2px 12px #649288)'
    }
  },
  text: {
    background: 'linear-gradient(280.42deg, #65F4CD 1.7%, #4799E9 83.14%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Poppins',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '28px',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  }
}));
