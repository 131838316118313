import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ethers } from 'ethers';
import { SwapDrawer } from '@/shared/components/swapDrawer';
import { useStyles, IClaimDrawer } from '.';
import { StakeButton } from '@/shared/components/stakeButton';
import { observer } from 'mobx-react';
import { checkStakerContract } from '@/shared/services/checkStakerContract';
import { metamaskService } from '@/shared/services/metamask';
import { ZERO } from '@/shared/services/checkContract';

export const ClaimDrawer = observer(
  ({ open, handleClickBackBtn, handleSuccess, handleFailed }: IClaimDrawer) => {
    const classes = useStyles();
    const [loaded, setLoaded] = useState(false);
    const { t } = useTranslation('common');

    useEffect(() => {
      checkStakerContract.pendingReward();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [metamaskService.wallet.account, metamaskService.wallet.chainId]);

    const claimBody = [
      {
        name: t('rewards-earned'),
        value: ethers.utils.commify(
          ethers.utils.formatUnits(checkStakerContract.pendingRewardValue || ZERO, 6)
        )
      }
    ];

    const successCallbackFn = () => {
      handleClickBackBtn();
      handleSuccess('claim-completed-successfully', claimBody);
      checkStakerContract.pendingReward();
      setLoaded(false);
    };

    const errorCallbackFn = () => {
      handleClickBackBtn();
      handleFailed('transaction-denied-by-user', claimBody);
      setLoaded(false);
    };

    const handleClickClaim = () => {
      setLoaded(true);
      checkStakerContract.claim(successCallbackFn, errorCallbackFn);
    };

    return (
      <SwapDrawer
        title={t('are-you-sure-to-claim')}
        open={open}
        handleClickBackBtn={handleClickBackBtn}
      >
        <div className={classes.wrapper}>
          <p className={classes.text}>{t('rewards-earned')}</p>
          <p className={classes.text}>
            {ethers.utils.formatUnits(checkStakerContract.pendingRewardValue || ZERO, 6)}
          </p>
        </div>
        <StakeButton
          text={t('claim')}
          className={classes.claim}
          onClick={handleClickClaim}
          disabled={checkStakerContract?.pendingRewardValue?.toNumber() === 0}
          loaded={loaded}
        />
      </SwapDrawer>
    );
  }
);
