import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    paddingRight: 5,
    overflow: 'auto',
    marginBottom: 50,
    '&::-webkit-scrollbar': {
      width: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'linear-gradient(280.42deg, #65F4CD 1.7%, #4799E9 83.14%)',
      borderRadius: '3px'
    }
  },
  mainContainer: {
    margin: '8px 0'
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  header: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '24px',
    color: theme.palette.primary.main
  },
  text: {
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '24px',
    color: theme.palette.primary.dark
  },
  unstake: {
    width: 76,
    height: 32,
    textTransform: 'capitalize'
  },
  noEntriesContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noEntries: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 14,
    lineHeight: '36px',
    color: '#95ADAC'
  },
  line: {
    width: '100%',
    height: 1,
    background:
      'linear-gradient(90deg, rgba(149, 173, 172, 0.5) -69.21%, rgba(149, 173, 172, 0.5) 100%)'
  }
}));
