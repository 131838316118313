import { observer } from 'mobx-react';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { BigNumber, ethers } from 'ethers';
import { checkStakerContract } from '@/shared/services/checkStakerContract';
import { useStyles } from './';
import { IUnstakeItem } from '../unstakeTable';
import {
  DECIMAL_DEFAULT_POINT,
  DECIMAL_PERCENT,
  DECIMAL_PERCENT_FRACTIONAL_PART,
  ZERO
} from '@/shared/services/checkContract';

export const UnstakeInfo = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const yourStakeValue = checkStakerContract.stakeDeposits?.reduce(
    (acc: BigNumber, val: IUnstakeItem) => {
      acc = acc.add(val?.stakeAmount);

      return acc;
    },
    BigNumber.from(ZERO)
  );

  const totalShare = ethers.utils.formatUnits(
    yourStakeValue
      ?.mul(DECIMAL_PERCENT_FRACTIONAL_PART)
      ?.div(checkStakerContract.totalStakedValue?.toString() || 1) || ZERO,
    DECIMAL_PERCENT
  );

  return (
    <Fragment>
      <div className={classes.wrapper}>
        <p className={classes.text}>{t('total-staked')}</p>
        <p className={classes.text}>
          {ethers.utils.commify(
            ethers.utils.formatUnits(
              BigNumber.from(checkStakerContract.totalStakedValue || ZERO),
              DECIMAL_DEFAULT_POINT
            )
          )}
        </p>
      </div>
      <div className={classes.wrapper}>
        <p className={classes.text}>{t('total-share')}</p>
        <p className={classes.text}>{`${totalShare} %`}</p>
      </div>
    </Fragment>
  );
});
