import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { ethers } from 'ethers';
import clsx from 'clsx';
import { InputWithMaxBtn } from '@/shared/components/inputWithMaxBtn';
import check from '@/shared/images/checkWithoutBG.png';
import { useStyles, IInputStakeValue } from '.';

export const InputStakeValue = observer(
  ({ className, errorMessage, setValue, maxValue, control }: IInputStakeValue) => {
    const classes = useStyles();
    const { t } = useTranslation('common');

    return (
      <div className={clsx([classes.container], [className])}>
        <div className={classes.wrapper}>
          <InputWithMaxBtn
            maxValue={maxValue}
            errorMessage={errorMessage}
            setValue={setValue}
            control={control}
          />
          <p className={classes.balance}>
            {t('balance')}: {ethers.utils.commify(maxValue)} {t('check')}
          </p>
        </div>
        <div className={classes.checkWrapper}>
          <img src={check} alt="" className={classes.img} />
          <p className={classes.check}>{t('check')}</p>
        </div>
      </div>
    );
  }
);
