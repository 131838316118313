import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 40,
    [theme.breakpoints.down('md')]: {
      marginTop: 32
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 22
    }
  },
  input: {
    marginTop: -8
  },
  receive: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 16,
    [theme.breakpoints.down('xs')]: {
      marginTop: 12
    }
  },
  text: {
    fontSize: 14,
    lineHeight: '24px',
    color: '#95ADAC',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    }
  },
  unstake: {
    marginLeft: 24,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 15
    }
  }
}));
