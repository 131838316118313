import { makeStyles } from '@material-ui/core/styles';
import { IBackground } from './';

export const useStyles = makeStyles((theme) => ({
  buttonWrapper: {
    width: '100%',
    height: 59,
    borderRadius: 20,
    background: ({ disabled }: IBackground) =>
      disabled ? '#95ADAC' : 'linear-gradient(90deg, #00B897 -69.21%, #00F2FE 100%)',
    border: 'solid 1px transparent',
    position: 'relative',
    outline: 'none',
    [theme.breakpoints.down('md')]: {
      height: 52
    },
    [theme.breakpoints.down('xs')]: {
      height: 46
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      backgroundColor: '#0f0f0ff5',
      borderRadius: 'inherit'
    }
  },
  stakeButton: {
    background: 'rgba(74, 106, 99, 0.2)',
    boxShadow: '0px -2px 12px rgba(100, 146, 136, 0.25)',
    borderRadius: 'inherit',
    width: '100%',
    height: 'inherit',
    color: 'white!important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'bold',
    fontWeight: 600,
    lineHeight: '28px',
    letterSpacing: '0.2px',
    textAlign: 'left',
    textTransform: 'none',
    [theme.breakpoints.down('xs')]: {
      fontSize: 12
    },
    '&:disabled': {
      color: '#95ADAC'
    }
  },
  progress: {
    color: '#95ADAC !important',
    marginRight: 8
  }
}));
