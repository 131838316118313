import { makeStyles } from '@material-ui/core/styles';
import filledButton from '@/shared/images/filledButton.png';
import hoverFilledButton from '@/shared/images/hoverFilledButton.png';
import { IFilledStyles } from '.';

export const useStyles = makeStyles((theme) => ({
  button: ({ isSmall, disabled }: IFilledStyles) => ({
    height: isSmall ? 40 : 64,
    width: isSmall ? 141 : 222,
    background: `url(${filledButton}) no-repeat`,
    backgroundSize: '100% 100%',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
      height: isSmall ? 32 : 42,
      width: isSmall ? 87 : 130
    },
    opacity: disabled ? 0.4 : 1,

    '&:hover': {
      '& div': {
        opacity: 1,
        zIndex: 0
      }
    }
  }),
  text: {
    zIndex: 1
  },
  buttonHover: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    transition: '0.2s',

    '&::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: `url(${hoverFilledButton}) no-repeat`,
      backgroundSize: '100%',
      zIndex: 1
    }
  },
  smallText: {
    fontSize: '14px',
    letterSpacing: '0.2px',
    [theme.breakpoints.down('sm')]: {
      fontSize: 12
    }
  },
  topShadow: {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '85%',
    height: '10px',
    filter: 'blur(5px)',
    background: '#489DE8',
    borderRadius: '20px',
    zIndex: 1,
    opacity: 0,
    transition: '0.2s'
  },
  bottomShadow: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '85%',
    height: '10px',
    filter: 'blur(5px)',
    background: '#00DBD1',
    borderRadius: '20px',
    zIndex: 1,
    opacity: 0,
    transition: '0.2s'
  },
  img: {
    marginLeft: 5
  },
  contentWrapper: {
    display: 'flex',
    alignItems: 'center'
  }
}));
