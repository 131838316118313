import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Backdrop } from '@material-ui/core';
import { IStaking, useStyles } from './';
import { Tabs } from '@/shared/components/tabs';
import { metamaskService } from '@/shared/services/metamask';
import { ClaimDrawer } from '@/entities/claimDrawer';
import { FailedDrawer } from '@/entities/failedDrawer';
import { LockDrawer } from '@/entities/lockDrawer';
import { UnstakeDrawer } from '@/entities/unstakeDrawer';
import { SuccessfulDrawer } from '@/entities/successfulDrawer';
import { Stake } from '@/features/stake';
import { Lock } from '@/features/lock';
import { Unstake } from '@/features/unstake';
import { checkContract } from '@/shared/services/checkContract';
import { checkStakerContract } from '@/shared/services/checkStakerContract';
import { IStakeBody } from '../stake/types';
import { IUnstakeItem } from '@/entities/unstakeTable';
import { checkLockerContract } from '@/shared/services/checkLockerContract';

const DOWNLOAD_METAMASK = 'https://metamask.io/download/';
const DELAY_GETTING_DATA = 120000;

export const Staking: FC<IStaking> = observer(() => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const [isOpenClaim, setIsOpenClaim] = useState(false);
  const [isOpenLock, setIsOpenLock] = useState(false);
  const [isOpenUnstake, setIsOpenUnstake] = useState(false);
  const [selectedUnstake, setSelectedUnstake] = useState<IUnstakeItem>();
  const [lockValue, setLockValue] = useState<string>();
  const [isOpenSuccessful, setIsOpenSuccessful] = useState(false);
  const [callBackTitle, setCallBackTitle] = useState('');
  const [callBackBody, setCallBackBody] = useState<Array<IStakeBody>>([]);
  const [isOpenFailed, setIsOpenFailed] = useState(false);

  const updateData = async () => {
    await checkStakerContract.stakePeriod();
    await checkStakerContract.totalStaked();
    checkStakerContract.stakedOf();
    checkContract.balanceOf();
    checkStakerContract.depositsOf();
    checkLockerContract.totalLocked();
    checkLockerContract.lockedOf();
  };

  useEffect(() => {
    updateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metamaskService.wallet.account, metamaskService.wallet.chainId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (metamaskService.wallet.account) {
        updateData();
      }
    }, DELAY_GETTING_DATA);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metamaskService.wallet.account, metamaskService.wallet.chainId]);

  const handleClickBackBtnClaim = () => {
    setIsOpenClaim(false);
  };

  const handleClickBackBtnLock = () => {
    setIsOpenLock(false);
  };

  const handleClickBtnUnstake = () => {
    setIsOpenUnstake(false);
  };

  const handleClickBtnSuccessfull = () => {
    setIsOpenSuccessful(false);
  };

  const handleClickBtnFailed = () => {
    setIsOpenFailed(false);
  };

  const handleFailed = (title: string, body: Array<IStakeBody>) => {
    setCallBackTitle(title);
    setCallBackBody(body);
    setIsOpenFailed(true);
  };

  const handleSuccess = (title: string, body: Array<IStakeBody>) => {
    setCallBackTitle(title);
    setCallBackBody(body);
    setIsOpenSuccessful(true);
  };

  const handleClickUnstake = (value: IUnstakeItem) => {
    setSelectedUnstake(value);
    setIsOpenUnstake(true);
  };

  const handleClickClaim = () => {
    setIsOpenClaim(true);
  };

  const handleClickBackDropBtn = () => {
    setIsOpenClaim(false);
    setIsOpenLock(false);
    setIsOpenUnstake(false);
    setIsOpenSuccessful(false);
    setIsOpenFailed(false);
  };

  const handleLock = (value: string) => {
    setLockValue(value);
    setIsOpenLock(true);
  };

  const handleConnect = () => {
    if (metamaskService.checkIfMetamaskInstalled()) {
      metamaskService.connect();
    } else {
      window.open(DOWNLOAD_METAMASK, '_target');
    }
  };

  const tabsConfig = [
    {
      label: t('stake'),
      component: (
        <Stake
          handleConnect={handleConnect}
          handleClickClaim={handleClickClaim}
          handleSuccess={handleSuccess}
          handleFailed={handleFailed}
        />
      )
    },
    // {
    //   label: t('lock'),
    //   component: <Lock handleConnect={handleConnect} handleLock={handleLock} />
    // },
    {
      label: t('unstake'),
      component: <Unstake handleConnect={handleConnect} handleClickUnstake={handleClickUnstake} />
    }
  ];

  return (
    <div className={classes.container}>
      <Backdrop
        open={isOpenClaim || isOpenLock || isOpenUnstake || isOpenSuccessful || isOpenFailed}
        className={classes.backdrop}
        onClick={handleClickBackDropBtn}
      />
      <div className={classes.wrapper}>
        <Tabs config={tabsConfig} className={classes.tabs} />
        <ClaimDrawer
          open={isOpenClaim}
          handleClickBackBtn={handleClickBackBtnClaim}
          handleSuccess={handleSuccess}
          handleFailed={handleFailed}
        />
        <LockDrawer
          open={isOpenLock}
          handleClickBackBtn={handleClickBackBtnLock}
          lockValue={lockValue}
          handleSuccess={handleSuccess}
          handleFailed={handleFailed}
        />
        <UnstakeDrawer
          open={isOpenUnstake}
          handleClickBackBtn={handleClickBtnUnstake}
          selectedUnstake={selectedUnstake}
          handleSuccess={handleSuccess}
          handleFailed={handleFailed}
        />
        <SuccessfulDrawer
          open={isOpenSuccessful}
          handleClickBackBtn={handleClickBtnSuccessfull}
          title={callBackTitle}
          body={callBackBody}
        />
        <FailedDrawer
          open={isOpenFailed}
          handleClickBackBtn={handleClickBtnFailed}
          title={callBackTitle}
          body={callBackBody}
        />
      </div>
    </div>
  );
});
