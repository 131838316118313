import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, CircularProgress } from '@material-ui/core';
import { useStyles, IStakeButton } from '.';

export const StakeButton = ({
  text,
  onClick,
  className,
  disabled = false,
  loaded = false,
  defaultLoading = false
}: IStakeButton) => {
  const classes = useStyles({ disabled: disabled || loaded });
  const { t } = useTranslation('common');

  return (
    <div className={clsx([classes.buttonWrapper], [className])}>
      <Button onClick={onClick} className={classes.stakeButton} disabled={disabled || loaded}>
        {loaded && <CircularProgress className={classes.progress} size={18} />}
        {loaded && !defaultLoading ? t('transaction-confirmation-pending') : text}
      </Button>
    </div>
  );
};
