import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { IStake, useStyles } from '.';
import { BaseLayout } from '@/shared/components/baseLayout';
import { BackgroundGrit } from '@/shared/components/backgroundGrit';
import { Staking } from '@/features/staking';
import { StakingDescriptionInfo } from '@/entities/stakingDescriptionInfo';
import { LeftShadow } from './leftShadow';
import { RightShadow } from './rightShadow';
import { Swap } from '@/features/swap';

export const StakePage = ({ id }: IStake): ReactElement => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <div className={classes.pageWrapper}>
      <BackgroundGrit className={classes.grit} />
      <BaseLayout id={id}>
        <div className={classes.contentWrapper}>
          <LeftShadow />
          <RightShadow />
          <div className={classes.container}>
            <Staking />
            <div className={classes.textWrapper}>
              <div className={classes.wrapper}>
                <Typography variant="h1" color="primary" className={classes.title}>
                  {t('stake-title')}
                </Typography>
                <Typography variant="body1" color="secondary" className={classes.description}>
                  {t('stake-description')}
                </Typography>
                <StakingDescriptionInfo />
              </div>
            </div>
          </div>
        </div>
      </BaseLayout>
    </div>
  );
};
