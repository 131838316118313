import React, { FC } from 'react';
import clsx from 'clsx';
import { Button, Typography } from '@material-ui/core';
import { IFilledButton, useStyles } from './';

export const FilledButton: FC<IFilledButton> = ({
  text,
  isSmall = false,
  img,
  onClick = () => {},
  disabled,
  className: propStyles
}) => {
  const classes = useStyles({ isSmall, disabled });

  return (
    <Button className={clsx([classes.button], propStyles)} onClick={onClick} disabled={disabled}>
      <div id="hover" className={classes.buttonHover}>
        <div className={classes.topShadow} />
        <div className={classes.bottomShadow} />
      </div>
      <div className={classes.contentWrapper}>
        <Typography
          className={clsx(classes.text, { [classes.smallText]: isSmall })}
          color="primary"
          variant="body2"
        >
          {text}
        </Typography>
        {img && <img src={img} alt="" className={classes.img} />}
      </div>
    </Button>
  );
};
