import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { StakeButton } from '@/shared/components/stakeButton';
import { UnstakeInfo } from '@/entities/unstakeInfo';
import { metamaskService, CHAIN_DATA } from '@/shared/services/metamask';
import { useStyles, IUnstake } from './';
import { UnstakeTable } from '@/entities/unstakeTable';
import { checkStakerContract } from '@/shared/services/checkStakerContract';
import { SwitchNetworkButton } from '@/entities/switchNetworkButton';
import { isMoreThanMobileView } from '@/shared/constants';
import { MobileUnstakeView } from '@/entities/mobileUnstakeView';

export const Unstake = observer(({ handleConnect, handleClickUnstake }: IUnstake) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <div className={classes.container}>
      <div />
      {!metamaskService.wallet.account ? (
        <StakeButton
          text={t('connect-wallet')}
          onClick={handleConnect}
          className={classes.button}
        />
      ) : (
        <Fragment>
          <div className={classes.wrapper}>
            <UnstakeInfo />
            <div className={classes.line} />
          </div>
          {isMoreThanMobileView ? (
            <UnstakeTable
              config={checkStakerContract.stakeDeposits}
              handleClickUnstake={handleClickUnstake}
            />
          ) : (
            <MobileUnstakeView
              config={checkStakerContract.stakeDeposits}
              handleClickUnstake={handleClickUnstake}
            />
          )}
          {Number(metamaskService.wallet.chainId) !==
            CHAIN_DATA?.[process.env.REACT_APP_CHAIN_ID as keyof typeof CHAIN_DATA].chainId && (
            <SwitchNetworkButton />
          )}
        </Fragment>
      )}
    </div>
  );
});
