import React, { useState } from 'react';
import { Tab } from '@material-ui/core';
import { ITabsProps, ITabs } from './';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';

export const Tabs = ({ config, className }: ITabsProps) => {
  const [value, setValue] = useState('0');
  const handleChange = (_: React.SyntheticEvent<EventTarget>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <TabList className={className} onChange={handleChange}>
        {config.map(({ label }: ITabs, index: number) => (
          <Tab label={label} value={index.toString()} />
        ))}
      </TabList>
      {config.map(({ component }: ITabs, index: number) => (
        <TabPanel value={index.toString()}>{component}</TabPanel>
      ))}
    </TabContext>
  );
};
