import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useStyles, IRewardsLocked } from './';

export const RewardsLocked = ({ value, className }: IRewardsLocked) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <div className={clsx([classes.wrapper], [className])}>
      <p className={classes.text}>{t('tokens-locked')}</p>
      <p className={classes.text}>{`${value} ${t('check')}`}</p>
    </div>
  );
};
