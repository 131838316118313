import * as yup from 'yup';
import { InternalOptions } from 'yup/lib/types';

export const stakeValidationSchema = (
  // eslint-disable-next-line @typescript-eslint/ban-types
  t: (value: string, object?: Object) => string,
  maxValue: string
) =>
  yup.object().shape({
    value: yup
      .string()
      .required('required-field')
      .test(
        'Is positive?',
        t('can-be-only-positive-number'),
        (value: unknown) => parseFloat(value as string) > 0
      )
      .test(
        'maxDigitsAfterDecimal',
        t('cant-be-more-than', { maxValue }),
        (_, { originalValue }: InternalOptions) =>
          parseFloat(originalValue?.replace(/,/g, '')) <= parseFloat(maxValue)
      )
      .transform((value: string, string: string) => (string === '' ? undefined : value))
      .test(
        'maxDigitsAfterDecimal',
        t('less_six_decimal_after_digits'),
        (_, { originalValue }: InternalOptions) =>
          /^\d+(\.\d{1,6})?$/.test(originalValue?.replace(/,/g, ''))
      )
  });
