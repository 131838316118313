export enum CheckStakerContractMethods {
  Claim = 'claim',
  TotalStaked = 'totalStaked',
  StakedOf = 'stakedOf',
  PendingReward = 'pendingReward',
  Withdraw = 'withdraw',
  Deposit = 'deposit',
  StakeDeposits = 'stakeDeposits',
  UnstakePercentage = 'unstakePercentage',
  StakePeriod = 'stakePeriod'
}
