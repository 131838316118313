import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import stakingRules from '@/shared/images/stakingRules.png';
import lockingRules from '@/shared/images/lockingRules.png';
import { Tooltip } from '@/shared/components/tooltip';
import { useStyles } from './';

export const StakingDescriptionInfo = () => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <div className={classes.container}>
      <Tooltip
        title={t('staking-rules-tooltip')}
        placement="top"
        classes={{ tooltip: classes.stakingTooltip }}
      >
        <div className={classes.wrapper}>
          <Fragment>
            <img src={stakingRules} alt="" className={classes.img} />
            <p className={classes.text}>{t('staking-rules')}</p>
          </Fragment>
        </div>
      </Tooltip>
      <Tooltip
        title={t('locking-rules-tooltip')}
        placement="top"
        classes={{ tooltip: classes.lockingTooltip }}
      >
        <div className={clsx([classes.wrapper], [classes.secondWrapper])}>
          <img src={lockingRules} alt="" className={classes.img} />
          <p className={classes.text}>{t('locking-rules')}</p>
        </div>
      </Tooltip>
    </div>
  );
};
