import React, { useState } from 'react';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { ethers } from 'ethers';
import { Decimal } from 'decimal.js';
import { SwapDrawer } from '@/shared/components/swapDrawer';
import { StakeButton } from '@/shared/components/stakeButton';
import { InputUnstakeValue } from '@/shared/components/inputUnstakeValue';
import {
  checkContract,
  DECIMAL_DEFAULT_FRACTIONAL_PART,
  DECIMAL_DEFAULT_POINT,
  ZERO
} from '@/shared/services/checkContract';
import { useStyles, IUnstakeDrawer, IUnstakeFormData, unstakeValidationSchema } from '.';
import { checkStakerContract } from '@/shared/services/checkStakerContract';

export const UnstakeDrawer = ({
  open,
  handleClickBackBtn,
  selectedUnstake,
  handleSuccess,
  handleFailed
}: IUnstakeDrawer) => {
  const classes = useStyles();
  const [loaded, setLoaded] = useState(false);
  const [conversionRate, setConversionRate] = useState(0);
  const { t } = useTranslation('common');
  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: { errors, isValid }
  } = useForm<IUnstakeFormData>({
    resolver: yupResolver(
      unstakeValidationSchema(
        t,
        ethers.utils.formatUnits(selectedUnstake?.stakeAmount || ZERO, DECIMAL_DEFAULT_POINT)
      )
    ),
    mode: 'onChange'
  });
  const getConversionRate = async () => {
    const rate = await checkStakerContract.unstakePercentage(selectedUnstake?.index);
    setConversionRate(Number(ethers.utils.commify(ethers.utils.formatUnits(rate, 4))));
  };

  getConversionRate();
  const unstakeValue = watch('value')?.replace(/,/g, '') || '';
  const unstake = Number(isValid ? unstakeValue : ZERO);
  const today = moment();
  const beforeUnstakeDate = today.isBefore(moment(selectedUnstake?.stakeTime, 'MMM Do, YYYY'));

  const unstakeBody = [
    {
      name: t('unstake'),
      value: `${ethers.utils.commify(
        ethers.utils.formatUnits(
          new Decimal(unstake.toFixed(DECIMAL_DEFAULT_POINT))
            .mul(DECIMAL_DEFAULT_FRACTIONAL_PART)
            .toFixed(),
          DECIMAL_DEFAULT_POINT
        )
      )} ${t('check')}`
    },
    {
      name: t('you-receive'),
      value: `${ethers.utils.commify(
        ethers.utils.formatUnits(
          new Decimal(new Decimal(unstake).mul(conversionRate).toFixed(DECIMAL_DEFAULT_POINT))
            .mul(DECIMAL_DEFAULT_FRACTIONAL_PART)
            .toFixed(),
          DECIMAL_DEFAULT_POINT
        )
      )} ${t('check')}`
    }
  ];

  const clearUnstakeValue = () => {
    setValue('value', '');
  };

  const successCallbackFn = async () => {
    handleClickBackBtn();
    handleSuccess('unstake-completed-successfully', unstakeBody);
    await checkStakerContract.totalStaked();
    checkStakerContract.pendingReward();
    checkStakerContract.stakedOf();
    checkStakerContract.depositsOf();
    checkContract.balanceOf();
    clearUnstakeValue();
    setLoaded(false);
  };

  const errorCallBackFn = () => {
    handleClickBackBtn();
    handleFailed('unstaking-denied-by-user', []);
    clearUnstakeValue();
    setLoaded(false);
  };

  const handleUnstake = () => {
    setLoaded(true);
    checkStakerContract.withdraw(
      unstakeValue,
      selectedUnstake?.index,
      successCallbackFn,
      errorCallBackFn
    );
  };

  return (
    <SwapDrawer
      title={t('are-you-sure-to-unstake')}
      secondTitle={beforeUnstakeDate ? t('unstake-second-title-after-date') : ''}
      open={open}
      handleClickBackBtn={() => {
        handleClickBackBtn();
        clearUnstakeValue();
      }}
    >
      <InputUnstakeValue
        errorMessage={errors?.value?.message}
        setValue={setValue}
        maxValue={ethers.utils.formatUnits(
          selectedUnstake?.stakeAmount || ZERO,
          DECIMAL_DEFAULT_POINT
        )}
        control={control}
      />
      <div className={classes.receive}>
        <p className={classes.text}>{t('you-will-receive')}</p>
        <p className={classes.text}>
          {ethers.utils.formatUnits(
            new Decimal(new Decimal(unstake).mul(conversionRate).toFixed(DECIMAL_DEFAULT_POINT))
              .mul(DECIMAL_DEFAULT_FRACTIONAL_PART)
              .toFixed(),
            DECIMAL_DEFAULT_POINT
          )}{' '}
          {t('check')}
        </p>
      </div>
      <div className={classes.wrapper}>
        <StakeButton text={t('keep-staking')} onClick={handleClickBackBtn} />
        <StakeButton
          text={t('unstake')}
          className={classes.unstake}
          onClick={handleSubmit(handleUnstake)}
          loaded={loaded}
          defaultLoading
        />
      </div>
    </SwapDrawer>
  );
};
