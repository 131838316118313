import { makeStyles } from '@material-ui/core/styles';
import { IEarnedAndClaimStyles } from '.';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: 'fit-content',
    padding: '9px 25.5px',
    backgroundColor: '#0C0C0C',
    borderRadius: 20,
    marginTop: 24,
    [theme.breakpoints.down('md')]: {
      marginTop: 18,
      padding: '9px 25.5px'
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 16,
      padding: '16px 12px 10.5px 12px'
    }
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  earned: {
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
    letterSpacing: 0,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 14
    }
  },
  value: {
    fontWeight: 600,
    fontSize: 24,
    lineHeight: '42px',
    letterSpacing: 0,
    color: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      fontSize: 18
    }
  },
  claim: {
    width: 100,
    height: 40,
    textTransform: 'capitalize',
    [theme.breakpoints.down('xs')]: {
      width: 72,
      height: 32
    }
  },
  error: {
    color: theme.palette.error.main,
    display: ({ isError }: IEarnedAndClaimStyles) => (isError ? 'block' : 'none')
  }
}));
