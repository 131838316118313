import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles, IMaxBtn } from '.';

export const MaxBtn = ({ onClick }: IMaxBtn) => {
  const classes = useStyles();
  const { t } = useTranslation('common');

  return (
    <div className={classes.container} onClick={onClick}>
      <p className={classes.text}>{t('max')}</p>
    </div>
  );
};
