import React, { FC } from 'react';
import clsx from 'clsx';
import { Drawer, Typography, IconButton } from '@material-ui/core';
import { useStyles, ISwapDrawer } from './';
import back from '@/shared/images/back.png';
import { ReactComponent as Success } from '@/shared/images/svg/success.svg';
import { ReactComponent as Error } from '@/shared/images/svg/error.svg';

export const SwapDrawer: FC<ISwapDrawer> = ({
  title,
  secondTitle,
  children,
  open,
  handleClickBackBtn,
  error,
  success,
  drawerClassname
}) => {
  const classes = useStyles();

  return (
    <Drawer
      open={open}
      classes={{ root: clsx(classes.drawer, drawerClassname), paper: classes.paper }}
      variant="persistent"
      anchor="bottom"
    >
      <div className={classes.container}>
        <div className={classes.infoWrapper}>
          <IconButton className={classes.iconButton} onClick={handleClickBackBtn}>
            <img src={back} alt="back" />
          </IconButton>
          <div className={classes.mainWrapper}>
            <div className={classes.wrapper}>
              {success && <Success className={classes.img} />}
              {error && <Error className={classes.img} />}
              <Typography
                color="primary"
                variant="h3"
                className={clsx(
                  [classes.title],
                  { [classes.error]: error },
                  { [classes.success]: success }
                )}
              >
                {title}
              </Typography>
            </div>
            <p
              className={clsx([classes.secondTitle], {
                [classes.secondTitleOffset]: error || success
              })}
            >
              {secondTitle}
            </p>
          </div>
          <div />
        </div>
        <div className={classes.line} />
        {children}
      </div>
    </Drawer>
  );
};
