import React from 'react';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import { MaxBtn } from '@/shared/components/maxBtn';
import NumberFormat from 'react-number-format';
import { useStyles, IInputWithMaxBtn } from '.';

export const InputWithMaxBtn = ({
  setValue,
  maxValue,
  errorMessage,
  control
}: IInputWithMaxBtn) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const handleMaxBtn = () => setValue('value', maxValue, { shouldValidate: true });

  return (
    <Controller
      control={control}
      name="value"
      render={({ field: { onChange, name, value } }) => (
        <NumberFormat
          thousandSeparator
          error={Boolean(errorMessage)}
          helperText={errorMessage && t(`${errorMessage}`)}
          variant="outlined"
          className={classes.textField}
          color="primary"
          InputProps={{
            endAdornment: <MaxBtn onClick={handleMaxBtn} />
          }}
          placeholder={t('enter-amount')}
          customInput={TextField}
          name={name}
          value={value}
          onChange={onChange}
        />
      )}
    />
  );
};
