import { makeAutoObservable } from 'mobx';
import { BigNumber, ethers } from 'ethers';
import { Decimal } from 'decimal.js';
import checkLockerAbi from './checkLockerAbi.json';
import { CheckLockerContractMethods } from './';
import { checkContract, DECIMAL_DEFAULT_FRACTIONAL_PART, ZERO } from '../checkContract';
import { metamaskService } from '../metamask';

class CheckLockerContract {
  lockEnd = false;

  totalLockedValue: BigNumber = BigNumber.from(ZERO);

  lockedOfValue: BigNumber = BigNumber.from(ZERO);

  constructor() {
    makeAutoObservable(this);
  }

  requestContract = async (method: string, ...args: unknown[]) => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        process.env.REACT_APP_CHECK_LOCKER_CONTRACT_ADDRESS,
        checkLockerAbi,
        signer
      );
      const data = await contract[method](...args);

      return data;
    }
  };

  totalLocked = async () => {
    const totalLocked = await this.requestContract(CheckLockerContractMethods.TotalLocked);
    this.totalLockedValue = totalLocked;
  };

  islockEnd = async () => {
    const lockEnd = await this.requestContract(CheckLockerContractMethods.LockEnd);

    const lockEndStr = ethers.utils.commify(ethers.utils.formatUnits(lockEnd || 1, 6));
    const lockEndInt = +lockEndStr.replace(/[\,\.]+/g, '') * 1000;

    this.lockEnd = Date.now() > lockEndInt;
  };

  lockedOf = async () => {
    const lockedOf = await this.requestContract(
      CheckLockerContractMethods.LockedOf,
      metamaskService.wallet.account
    );
    this.lockedOfValue = lockedOf;
  };

  lock = async (lockValue = '', successCallbackFn = () => {}, errorCallbackFn = () => {}) => {
    const allowance = await checkContract.allowance(
      process.env.REACT_APP_CHECK_LOCKER_CONTRACT_ADDRESS
    );
    const allowanceValue = allowance.toNumber().toFixed();
    const lock = new Decimal(lockValue).mul(DECIMAL_DEFAULT_FRACTIONAL_PART).toFixed();
    if (Number(allowanceValue) < Number(lock)) {
      try {
        await checkContract.increaseAllowance(
          lock,
          process.env.REACT_APP_CHECK_LOCKER_CONTRACT_ADDRESS
        );
        const lockTransaction = await this.requestContract(CheckLockerContractMethods.Lock, lock);
        await lockTransaction.wait();
        successCallbackFn();
      } catch (err) {
        errorCallbackFn();
      }
    } else {
      try {
        const lockTransaction = await this.requestContract(CheckLockerContractMethods.Lock, lock);
        await lockTransaction.wait();
        successCallbackFn();
      } catch (err) {
        errorCallbackFn();
      }
    }
  };
}

export const checkLockerContract = new CheckLockerContract();
