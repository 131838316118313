import { observer } from 'mobx-react';
import React, { FC } from 'react';
import { BigNumber, ethers } from 'ethers';
import { useTranslation } from 'react-i18next';
import { Decimal } from 'decimal.js';
import { checkStakerContract } from '@/shared/services/checkStakerContract';
import { useStyles, IStakeInfo } from './';
import {
  DECIMAL_DEFAULT_POINT,
  DECIMAL_DEFAULT_FRACTIONAL_PART,
  ZERO,
  DECIMAL_PERCENT_FRACTIONAL_PART,
  DECIMAL_PERCENT
} from '@/shared/services/checkContract';

export const StakeInfo: FC<IStakeInfo> = observer(({ stakeValue = ZERO }) => {
  const classes = useStyles();
  const { t } = useTranslation('common');
  const allStakedValue = BigNumber.from(
    new Decimal(Number(stakeValue).toFixed(DECIMAL_DEFAULT_POINT))
      .mul(DECIMAL_DEFAULT_FRACTIONAL_PART)
      .toFixed()
  ).add(checkStakerContract.totalStakedValue || ZERO);

  const yourStakeValue =
    BigNumber.from(
      new Decimal(Number(stakeValue).toFixed(DECIMAL_DEFAULT_POINT))
        .mul(DECIMAL_DEFAULT_FRACTIONAL_PART)
        .toFixed()
    ).add(BigNumber.from(checkStakerContract.stakedOfValue || ZERO)) || ZERO;

  // const percentageYourStake = ethers.utils.formatUnits(
  //   BigNumber.from(
  //     new Decimal(Number(yourStakeValue).toFixed(DECIMAL_PERCENT))
  //       .mul(DECIMAL_PERCENT_FRACTIONAL_PART)
  //       .div(Number(allStakedValue).toFixed(DECIMAL_PERCENT))
  //   ),
  //   DECIMAL_PERCENT
  // );

  // const percentageYourStake = ethers.utils.formatUnits(
  //   yourStakeValue?.mul(DECIMAL_PERCENT_FRACTIONAL_PART)?.div(allStakedValue || 1) || ZERO,
  //   DECIMAL_PERCENT
  // );

  const percentageYourStake = BigNumber.from(
    yourStakeValue
      ?.mul(DECIMAL_PERCENT_FRACTIONAL_PART)
      ?.div(allStakedValue.isZero() ? 1 : allStakedValue) || ZERO.toFixed()
  );

  return (
    <div className={classes.container}>
      <div className={classes.wrapper}>
        <p className={classes.text}>{t('apr')}</p>
        <p className={classes.text}>{'13.46 %'}</p>
      </div>
      <div className={classes.wrapper}>
        <p className={classes.text}>{t('total-staked')}</p>
        <p className={classes.text}>
          {ethers.utils.commify(ethers.utils.formatUnits(allStakedValue, DECIMAL_DEFAULT_POINT))}
        </p>
      </div>
      <div className={classes.wrapper}>
        <p className={classes.text}>{t('your-stake')}</p>
        <p className={classes.text}>
          {`${ethers.utils.commify(
            ethers.utils.formatUnits(percentageYourStake, DECIMAL_PERCENT)
          )} %`}
        </p>
      </div>
    </div>
  );
});
