import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  container: {
    height: '100%',
    paddingRight: 5,
    '&::-webkit-scrollbar': {
      width: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: 'linear-gradient(280.42deg, #65F4CD 1.7%, #4799E9 83.14%)',
      borderRadius: '3px'
    }
  },
  unstake: {
    width: 98,
    height: 40,
    textTransform: 'capitalize'
  },
  lastCell: {
    display: 'flex !important',
    justifyContent: 'end'
  },
  noEntriesContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noEntries: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '36px',
    color: '#95ADAC'
  }
});
