import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    marginTop: 60,
    [theme.breakpoints.down('md')]: {
      marginTop: 32,
      marginBottom: 32
    },
    [theme.breakpoints.down('xs')]: {
      marginTop: 24,
      marginBottom: 24,
      flexDirection: 'column'
    }
  },
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  img: {
    width: 20,
    height: 20,
    marginRight: 8
  },
  text: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '24px',
    background: 'linear-gradient(90deg, #00B897 -69.21%, #00F2FE 100%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    textFillColor: 'transparent'
  },
  secondWrapper: {
    marginLeft: 62,
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginTop: 18
    }
  },
  stakingTooltip: {
    maxWidth: 363
  },
  lockingTooltip: {
    maxWidth: 430
  }
}));
