export const DECIMAL_DEFAULT_POINT = 6;
export const DECIMAL_PERCENT = 3;
export const DECIMAL_PERCENT_FRACTIONAL_PART = 100000;
export const DECIMAL_DEFAULT_FRACTIONAL_PART = 1000000;
export const ZERO = 0;
export const TEN = 10;
export enum CheckContractMethods {
  BalanceOf = 'balanceOf',
  Allowance = 'allowance',
  IncreaseAllowance = 'increaseAllowance'
}
