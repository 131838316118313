import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  lock: {
    marginTop: 40,
    [theme.breakpoints.down('xs')]: {
      marginTop: 24
    }
  }
}));
